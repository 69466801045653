.back {
  margin: auto;
  width: 10rem;
  height: 3rem;
  color: aliceblue;
  background-color: rgb(0, 0, 0);
  /* z-index: 550; */
  border-radius: 4px;
  font-size: 1rem;

  &::before {
    content: "";
    background-color: red;
    width: 5rem;
    position: relative;
    left: -10rem;
  }
}

.endlesTitle {
  position: relative;
  top: -5px;
  left: 0;
  width: 100%;
}

.endlesName {
  position: absolute;
  top: 20px;
  left: 24px;
  width: 100%;
  font-style: italic;
  color: var(white);
}

.btnGame {
  position: relative;
  margin: 0 5px;
  border: none;
  // border: 1px solid var(--contrast-text);
  background: var(--color-fill);
  color: var(--color-intense);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  min-width: 9rem;
  min-height: 4rem;

  svg {
    margin-right: 5px;
  }


  &:hover {
    background-color: var(--color-nav);
    color: white;


  }

  @media screen and (max-width: 900px) {
    margin-top: 2px;
  }
}

.btnVoice {
  // position: absolute;
  background-color: red;
  // z-index: 4000;
  height: 30px;
  width: 4px;
  min-width: 30px;


  transition: 0.8s ease;
  font-weight: bold;
  // position: absolute;
  bottom: 0;
  height: 40px;
  width: 40px;
  z-index: 5;
  font-size: 2rem;
  border-radius: 50%;
  border: none;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
  font-family: Arial;
  line-height: 2;
  transition: all 0.5s ease;
  background: var(--color-fill);
  background-color: transparent;
}

.voice-mainBox {
  position: relative;
  width: 4rem;
  z-index: 4000;
  position: absolute;
  bottom: 40px;
  left: 50%;
  top: -40px;
  // left: 45%;
  display: flex;
  height: 3rem;

  &:hover {
    .langs {
      opacity: 0.7;
      display: flex;
      padding: 0;

      .langEltrue {
        scale: 0.8;
        background-color: var(--color-fill);
        color: var(--color-nav);
      }
    }
  }

  .langs {
    z-index: 3500;
    position: absolute;
    left: 30px;
    top: 0px;
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 15px;
    transition: all 0.2s ease-in-out;
    height: 30px;
    width: 50px;
    flex-wrap: wrap;



    backdrop-filter: blur(13px);
    background-attachment: fixed;
    // box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    opacity: 0.95;
    background-color: var(--color-fill);
    background: transparent;

    // background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 5%, rgba(0, 0, 0, 0.1) 95%, rgba(0, 0, 0, 0) 100%);
    padding-right: 7px;

    .lang,
    .langElfalse,
    .langEltrue {
      z-index: 500;
      // position: absolute;
      // right: -15px;
      // top: -15px;
      border-radius: 50%;
      border: 2px solid var(--color-fill);
      height: 30px;
      width: 40px;
      background: var(--color-accent);
      color: var(--color-nav);
      transition: all 0.2s ease-in-out;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      display: none;
      align-items: center;
      justify-content: center;
    }

    .langElfalse {
      display: none;
      visibility: hidden;
      // display: flex;
      height: 0;
      scale: 0;
      background-color: var(--back-first);
      transition: all 0.4s ease-in-out;
    }

    .langEltrue {
      display: flex;
      background-color: var(--color-nav);
      color: var(--color-fill);
    }

    p {
      display: inline;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding: 0;
      margin: 0;
    }

    &:hover {
      color: var(--color-bg-wrap);
      box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5), -4px -4px 6px 0 rgba(116, 125, 136, 0.5), inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2), inset 4px 4px 6px 0 rgba(0, 0, 0, 0.4);



      flex-wrap: wrap;
      flex-direction: row;
      height: -moz-fit-content;
      height: 200px;
      max-height: 200px;
      max-width: -moz-fit-content;
      width: 150px;
      padding: 5px;
      overflow-y: auto;
      background-color: var(--color-fill);
      opacity: 1;

      .langElfalse {
        scale: 1;
        // display: flex;
        visibility: visible;
        height: 30px;
        display: flex;
        transition: all 0.1s ease-in-out;

        &:hover {
          background-color: var(--color-nav);
          color: var(--color-fill);
          opacity: 0.7;

        }
      }

      .langEltrue {
        scale: 1;
        background-color: var(--color-nav);
        color: var(--color-fill);
      }
    }


  }


  .btnVoice {

    color: black;
    color: var(--main-color);
    color: var(--color-intense);

    &:hover {
      box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5), -4px -4px 6px 0 rgba(116, 125, 136, 0.5), inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2), inset 4px 4px 6px 0 rgba(0, 0, 0, 0.4);
      // box-shadow: none;
    }
  }

  // .langs {
  //   flex-wrap: wrap;


  //   .langElfalse {
  //     display: none;
  //     visibility: hidden;
  //     // display: flex;
  //     height: 0;
  //     scale: 0;
  //     background-color: var(--back-first);
  //     transition: all 0.4s ease-in-out;
  //   }

  //   .langEltrue {
  //     display: flex;
  //     background-color: var(--color-nav);
  //     color: var(--color-fill);
  //   }

  //   &:hover {
  //     .langElfalse {
  //       scale: 1;
  //       // display: flex;
  //       visibility: visible;
  //       height: 30px;
  //       display: flex;
  //       transition: all 0.1s ease-in-out;

  //       &:hover {
  //         background-color: var(--color-nav);
  //         color: var(--color-fill);
  //         opacity: 0.7;

  //       }
  //     }
  //   }
  // }


  // }


}