@import 'animate.css';

.dropdown-toggle,
.dropdown-toggle-menu {
    position: relative;
    border: none;
    width: 10rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;


    &:hover {

        background-color: var(--color-fill);
        border: 0.5px solid var(--border-fill);
        // color: var(--border-fill)
    }

    svg {
        margin-right: 5px;
        // padding: 2px;
        font-size: 14px;



    }
}

.dropdown-toggle-menu {


    text-transform: uppercase;
    border: 0.5px solid var(--border-fill);
    background-color: var(--color-fill);
    color: var(--color-menuG);
    font-weight: 700;

    &:hover {
        background: var(--background-nav);
        color: white;

        border: 0.5px solid var(--border-fill);
    }
}

.dropdown-menu {
    position: absolute;
    list-style: none;
    text-align: left;
    cursor: pointer;
    background-color: var(--color-fill);
    width: 10rem;
    z-index: 1100;
    margin-top: 1rem;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

    li {
        margin: 3px 0;
    }

    @media screen and (max-width:900px) {
        font-size: 14px;

    }

}