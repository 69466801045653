.allcollect {
    position: relative;
    font-size: 10px;
    margin-top: 1rem;
    width: 100%;
    margin-left: 2rem;


    .categoryLine {
        font-size: 13px;
        text-align: left;
        font-weight: 600;
        BACKGROUND: var(--color-nav);
        COLOR: WHITE;
        margin: auto;
        MARGIN-BOTTOM: 5px;
        border-radius: 5px;
        padding: 0 5px;
        text-transform: uppercase;
        text-align: center;
        width: 90%;
    }

    @media screen and (max-width: 900px) {
        left: 0;
        margin-left: 0rem;
    }

    @media screen and (max-height: 600px) {
        left: 0;
        margin-left: 0rem;
    }
}

.card-view {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%;
    // transition: all 8s ease;

    @media screen and (max-width: 900px) {
        justify-content: center;
    }

    .chapterWrap {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        @media screen and (max-width: 900px) {
            flex-direction: column;
        }
    }

    .look0 {
        font-size: 20px;
        color: var(--color-intense);
        position: absolute;
        opacity: 0;
        bottom: 12%;
        left: 12%;
    }

    .oneCollect-wrap {

        position: relative;
        padding: 5px;
        transition: all 0.4s ease-in-out;

        @media screen and (max-width: 600px) {
            margin-bottom: 10px;
        }


        // .look {
        //     position: absolute;
        //     transition: all 0.8s ease-out;
        //     bottom: -3px;
        //     left: -3px;
        //     opacity: 0;
        //     z-index: 1;

        //     // &:hover {
        //     //     .oneCollect {
        //     //         .header {

        //     //             white-space: nowrap;
        //     //             BACKGROUND: var(--color-intense);
        //     //             COLOR: WHITE;
        //     //             border-bottom: 0.5px solid var(--border-fill);
        //     //             font-size: 14px;
        //     //             justify-content: space-between;
        //     //             height: auto;

        //     //             @media screen and (max-width: 600px) {
        //     //                 padding-left: 25px;
        //     //             }
        //     //         }

        //     //         .collectBody {
        //     //             display: block;
        //     //         }

        //     //         .playmenu {
        //     //             display: block;
        //     //         }

        //     //         .btns-div {


        //     //             .sharebtn,
        //     //             .favoritebtn {
        //     //                 display: block;
        //     //             }

        //     //             .shareSymb,
        //     //             .favoriteSymb {
        //     //                 display: none;
        //     //             }

        //     //             .delbtn {
        //     //                 display: block;
        //     //             }

        //     //             .mypbbtn {
        //     //                 display: block;
        //     //             }
        //     //         }

        //     //         span {
        //     //             display: none;
        //     //             border: 1px solid var(--color-checked);
        //     //             z-index: 4;
        //     //             color: var(--color-checked);
        //     //         }


        //     //         transform: translateY(-5px);
        //     //         box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
        //     //     }
        //     // }
        //     &:before {
        //         content: '';
        //         position: absolute;
        //         bottom: 6px;
        //         left: 10px;
        //         width: 25px;
        //         height: 30px;
        //         background: linear-gradient(30deg, #999 1px, #f1f1f1 90%);
        //         box-shadow: 0 0 0 1px var(--color-intense) inset;
        //         transform: skew(20deg, 20deg) rotate(-9deg);
        //         border-bottom-left-radius: 60px;
        //     }

        //     :after {
        //         content: '';
        //         position: absolute;
        //         bottom: 22px;
        //         left: 40px;
        //         width: 50px;
        //         height: 50px;
        //         transform: skew(-20deg);
        //         z-index: -1;
        //         box-shadow: -20px 20px 8px rgba(0, 0, 0, 0.2);
        //     }
        // }

        .oneCollect {
            background-color: var(--background-wrap);
            color: var(--color-bg-wrap);
            margin: 20px;
            width: 260px;
            min-width: 260px;
            height: 175px;
            position: relative;
            padding: 10px;
            border-radius: 10px;
            // transition: all 0.4s ease-in-out;
            cursor: help;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
            transition: inherit;

            @media screen and (max-width: 600px) {
                margin: 5px;
            }

            .playmenu {
                position: absolute;
                bottom: -10px;
                left: 0;
                display: none;
                transition: inherit;

            }

            .header {
                position: relative;
                text-transform: uppercase;
                display: flex;
                justify-content: center;
                padding: 0 5px;
                width: 100%;
                height: 100%;
                white-space: wrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: 500;
                font-size: 15px;
                opacity: 0.8;
                color: var(--color-nav);
                align-items: center;
                transition: inherit;
                // transition: all 0.4s ease-in-out;
                margin-top: 15px;

                @media screen and (max-width: 900px) {
                    padding-left: 25px;

                }
            }

            span {
                position: absolute;
                bottom: 5px;
                right: 10px;
                color: var(--color-bg-wrap);
                font-size: 1.2rem;
                opacity: 0.9;
                border-radius: 10px;
                font-size: 9px;
            }

            .collectBody {
                display: none;
                transition: inherit;

            }

            .content-row {
                margin: 5px 0;
                max-height: 20px;
                text-align: left;
                padding-left: 5px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                border-radius: 5px;
            }
        }

        .cat_tag {
            position: absolute;
            top: 0px;
            left: -10px;
            opacity: 0.9;
            max-width: 100%;
            min-width: 50%;
            width: fit-content;
            height: fit-content;
            padding-top: 10px;
            padding-left: 15px;
            padding-right: 15px;
            color: var(--color-bg-wrap);
            border-radius: 10px;
            text-align: start;
            font-size: 9px;
            transition: all 0.4s;
        }

        .btns-div {
            transition: inherit;

            .share {
                position: absolute;
                left: 0rem;
                top: 5px;
                font-size: 16px;
                border-radius: 38%;
                cursor: pointer;
                width: 2.4rem;
                height: 2.4rem;
                background-color: var(--background-second);

            }

            .shareSymb {
                @extend .share;
                background: transparent;
                border: none;
                box-shadow: none;
                top: 20px;
                left: unset;
                right: 30px;
                font-size: 10px;
                width: fit-content;
                height: fit-content;

                @media screen and (max-width: 600px) {
                    top: 10px;
                }
            }

            .heartSymb {
                color: #f92f60;
                left: unset;
                right: 40px;

                @media screen and (max-width: 600px) {
                    right: 15px;
                    top: 10px;
                }
            }

            .mypbbtn {
                @extend .shareSymb;
                cursor: auto;
            }

            .sharebtn {
                @extend .share;
                display: none;
                box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
                line-height: 1.3;
                font-size: 15px;
                top: 41px;
                left: unset;
                right: 1px;

                &:hover,
                .hov {
                    background-color: var(--background-header);
                    transform: scale(1.2);
                    opacity: 1;
                    z-index: 900;

                    &::before {
                        content: attr(title);
                        width: fit-content;
                        white-space: nowrap;
                        padding: 2px 5px;
                        background-color: var(--background-second);
                        opacity: 1;
                        color: var(--color-text);
                        border-radius: 5px;
                        font-size: 9px;
                        position: absolute;
                        bottom: -20px;
                        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
                        animation: fadeOut 4s forwards;
                        z-index: 2000;
                    }
                }

                @media screen and (max-width: 600px) {
                    opacity: 1;
                    top: 26px;
                    right: -10px;
                }
            }

            .heartbtn {
                color: #f92f60;
                right: 1px;
                top: 68px;

                @media screen and (max-width: 600px) {
                    top: 53px;
                    right: -10px;
                }
            }

            .prnbtn {
                left: 0;
                top: 18rem;

                @media screen and (max-width: 600px) {
                    top: 16rem;

                }
            }

            .delbtn {
                position: absolute;
                padding: 5px;
                font-size: 11px;
                display: none;
                cursor: pointer;
                transition: all 0.2s;
                top: 16px;
                right: 1px;

                &:hover {
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
                    transform: scale(1.2);
                }

                @media screen and (max-width: 600px) {
                    top: 0px;
                    right: -10px;
                }
            }
        }

        &:hover+.look0 {
            opacity: 1;
            // animation: heartBeat 2s forwards;
        }



    }

    .oneCollect-wrap:hover {
        .look0 {
            opacity: 1;
            animation: tada 2s forwards;

        }
    }

    .isHover+.look0 {
        opacity: 0;
    }

    .isHover {
        .look0 {
            opacity: 0;
        }

        .oneCollect {

            .header {

                white-space: nowrap;
                BACKGROUND: var(--color-intense);
                COLOR: WHITE;
                border-bottom: 0.5px solid var(--border-fill);
                font-size: 14px;
                justify-content: space-between;
                height: auto;
                border-radius: 5px;

                @media screen and (max-width: 600px) {
                    padding-left: 25px;
                }
            }

            .playmenu,
            .collectBody,
            .btns-div .sharebtn,
            .btns-div .favoritebtn,
            .btns-div .delbtn,
            .btns-div .mypbbtn {
                display: block;
            }

            .btns-div .shareSymb,
            .btns-div .favoriteSymb {
                display: none;
            }

            span {
                display: none;
                border: 1px solid var(--color-checked);
                z-index: 4;
                color: var(--color-checked);
            }

            transform: translateY(-5px);
        }
    }

    .categoryChapter,
    .letterChapter {
        font-size: 13px;
        text-align: left;
        font-weight: 600;
        BACKGROUND: var(--color-nav);
        COLOR: WHITE;
        MARGIN-BOTTOM: 5px;
        border-radius: 5px;
        padding: 0 5px;
        text-transform: uppercase;
        height: 180px;
        width: 30px;
        font-size: 10px;
        opacity: 0.8;
        transform: skewY(10deg);
        box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;

        @media screen and (max-width: 900px) {
            height: 30px;
            width: 208px;
            transform: none
        }
    }

    .letterChapter {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    .categoryChapter {
        writing-mode: vertical-rl;
        text-align: center;
        transform: rotate(180deg)skewY(10deg);
        box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
        transition: all 0.4s ease-in-out;

        &:hover {
            transform: rotate(180deg)skewY(10deg) translateY(5px);
        }

        @media screen and (max-width: 900px) {
            writing-mode: inherit;
            transform: none;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;

            &:hover {
                transform: translateY(-5px);
            }
        }
    }



}

.tbl_view {
    max-width: 90%;
    margin: auto;
    background-color: var(--background-wrap);
    color: var(--color-bg-wrap);
    width: 100%;
    min-width: 260px;
    height: fit-content;
    position: relative;
    border-radius: 10px;
    transition: all 0.4s ease-in-out;
    cursor: help;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 2rem;

    .look0 {
        display: none;
    }

    .listRow {
        display: flex;
        cursor: pointer;
        transition: all 0.4s ease-in-out;
        padding: 5px;

        &:nth-child(even) {
            background-color: var(--background-even);
        }

        &:nth-child(odd) {
            color: var(--background-note);
            background-color: var(--background-second);
        }

        .rowHeader {
            font-weight: 600;

        }

        &:hover {
            color: var(--color-checked);
            transform: translateX(10px);

        }
    }

    span {

        background-color: transparent;
        color: gray;
        width: fit-content;
        font-size: 10px;
    }


    .chapterWrap {
        background: transparent;
        width: 90%;
        margin: auto;

        @media screen and (max-width: 900px) {
            width: 100%;
        }
    }

    .categoryChapter,
    .letterChapter {
        font-size: 13px;
        text-align: left;
        font-weight: 600;
        BACKGROUND: var(--color-nav);
        COLOR: WHITE;
        MARGIN-BOTTOM: 5px;
        border-radius: 5px;
        padding: 0 5px;
        opacity: 0.8;
        text-transform: uppercase;
        writing-mode: inherit;
        text-orientation: inherit;
        transition: all 0.4s ease-in-out;
    }

    .categoryChapter {
        &:hover {
            transform: translateX(-5px);
        }

    }

    @media screen and (max-width: 900px) {

        width: 100%;
    }

    .oneCollect-wrap {

        color: var(--color-bg-wrap);
        width: 100%;
        min-width: 260px;
        height: -moz-fit-content;
        height: fit-content;
        position: relative;
        border-radius: 5px;
        transition: all 0.4s ease-in-out;
        cursor: help;
        display: flex;
        flex-direction: column;
        justify-content: space-between;



        @media screen and (max-width:900px) {
            width: 100%;
            margin: auto;
        }

        .oneCollect {
            display: flex;
            justify-content: flex-start;
            padding: 0 15px;
            width: 100%;
            font-size: 14px;
            border-radius: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 500;
            border-bottom: 0.5px solid var(--border-fill);
            BACKGROUND: var(--background-first);
            COLOR: var(--color-bg-wrap);
            opacity: 0.8;
            margin-bottom: 5px;
            padding: 5px 5px;


            @media screen and (max-width: 900px) {
                // margin: auto;
                justify-content: flex-start;
            }

            .playmenu {
                display: none;
            }

            .header {
                font-size: 14px;
                padding-left: 20px;
                text-transform: uppercase;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;


                @media screen and (max-width: 900px) {
                    padding-left: 30px;
                    text-align: left;
                    text-overflow: ellipsis;
                    text-wrap: nowrap;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    // font-size: 11px;
                    width: 80%;
                }
            }


            span {
                position: absolute;
                right: 30px;
                border: none;
                background-color: transparent;
                color: gray;
                width: fit-content;
                height: 20px;
                font-size: 9px;

                &::after {
                    content: " cards";
                }


                @media screen and (max-width: 900px) {
                    position: absolute;
                    top: 0px;
                    right: 30px;
                }
            }

            .collectBody {
                display: none;
                min-width: 70px;
                max-width: 200px;
                border: 1px solid var(--background-header);
                border: none;
                border-radius: 0;
                color: grey;
                font-style: italic;
                font-size: 10px;
                background-color: transparent;
                padding: 5px 10px;
                width: min-content;
            }

            .content-row {
                max-height: 20px;
                text-align: left;
                padding-left: 5px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: -6px 0;
                border-radius: 0px;
            }
        }

        .cat_tag {
            position: absolute;
            right: 100px;
            width: auto;
            height: 1.5em;
            max-width: fit-content;
            min-width: fit-content;
            padding: 0;
            border: none;
            border-radius: 0;
            background-color: transparent;
            color: var(--color-bg-wrap);
            font-size: 10px;

            @media screen and (max-width:900px) {
                min-width: 50px;
                width: 50px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                position: absolute;
                left: unset;
                right: 30px;
                top: unset;
                bottom: 10px;
            }
        }

        .btns-div {
            display: flex;
            justify-content: flex-end;
            width: 30px;

            @media screen and (max-width:900px) {
                left: 10px;
            }

            .share {
                position: relative;
                left: auto;
                top: auto;
                font-size: 10px;
                border-radius: 0;
                width: 2rem;
                height: 2rem;
                color: lightgray;
            }

            .shareSymb {
                @extend .share;
                background-color: transparent;
                border: none;
                box-shadow: none;
                position: absolute;
                left: 0px;
                top: 0px;
                color: grey;

                @media screen and (max-width: 900px) {
                    top: 5px;
                }
            }

            .heartSymb {
                color: #f92f60;
                position: absolute;
                left: 10px;
                top: 0px;

                @media screen and (max-width: 900px) {
                    left: 10px;
                    top: 5px;
                }
            }

            .mypbbtn {
                @extend .shareSymb;
                cursor: auto;
                top: 0px;
                position: absolute;

                @media screen and (max-width:900px) {
                    background-color: transparent;
                    box-shadow: none;
                    top: 5px;
                }
            }

            .sharebtn {
                @extend .share;
                display: none;
                box-shadow: none;
                border: none;
                background-color: transparent;
                line-height: 1.3;
                opacity: 1;
                top: 0px;

                &:hover {
                    background-color: transparent;
                    z-index: 900;


                    &::after {
                        content: unset;
                    }
                }
            }

            .heartbtn {
                color: #f92f60;
                left: 10px;
                top: 0px;
            }

            .prnbtn {
                position: relative;
                left: auto;
                font-size: 19px;
                top: auto;
            }

            .delbtn {
                position: absolute;
                right: 0px;
                top: 5px;
                padding: 2px;
                font-size: 19px;
                display: none;
                cursor: pointer;
                transition: all 0.2s;
                font-size: 8px;

                &:hover {
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
                    transform: scale(1.2);

                    &::after {
                        bottom: 3rem;
                    }
                }
            }
        }

        &:hover {
            transform: translateX(-10px);

            .header {
                color: var(--color-nav);

            }

            .collectBody {
                display: flex;
                max-width: fit-content;
                margin-right: 16rem;

                @media screen and (max-width: 900px) {
                    display: none;
                }
            }

            .btns-div {

                .delbtn {
                    display: block;
                }


            }

            span {
                display: block;
            }

            .oneCollect {
                background-color: var(--color-fill);
            }
        }
    }
}

.card-view-cat {
    display: flex;
    flex-wrap: wrap;

    .list-wrap {
        background-color: var(--background-wrap);
        color: var(--color-bg-wrap);
        margin: 20px;
        width: 260px;
        min-width: 260px;
        height: 175px;
        position: relative;
        padding: 10px;
        border-radius: 10px;
        transition: all 0.4s ease-in-out;
        cursor: help;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;


        button {
            height: 2.5rem;
            min-width: 2.5rem;
            line-height: 1;
            border-radius: 10px;
            transition: all 0.4s ease-in-out;
            background-color: var(--background-first);
            color: var(--color-bg-wrap);
            cursor: pointer;
            font-size: 12px;
            width: 2rem;
            height: 2rem;
            padding: 0;

            &:hover {
                scale: 1.2;
                border-radius: 2px;
            }
        }

        .listHeader {

            display: flex;
            justify-content: space-between;
            padding: 0 5px;
            width: 100%;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 500;
            border-bottom: 0.5px solid var(--border-fill);
            BACKGROUND: var(--color-nav);
            COLOR: WHITE;
            opacity: 0.8;
        }

        .listBody {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            height: 80%;
            overflow-x: hidden;
            scrollbar-color: var(--color-bg-wrap) var(--background-header);

            .listItem {
                width: 100%;
                position: relative;
                color: var(--color-bg-wrap);
                padding: 1px;
                padding-left: 5px;
                text-align: start;
                display: flex;
                justify-content: flex-start;
                transition: all 0.4s ease-in-out;

                span {
                    color: var(--color-bg-wrap);
                    text-align: start;
                    margin: unset;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 85%;
                    width: fit-content;
                }

                .item-btns {
                    display: none;
                    border: none;
                    transition: all 0.4s ease-in-out;
                    width: 10rem;
                    text-align: end;
                    background: var(--background-header);
                    height: 2.5rem;

                    button {
                        border: none;
                    }
                }

                .item-btns-static {
                    display: flex;
                    font-size: 10px;
                    color: GREY;
                }

                .item-btns-pb {
                    @extend .item-btns-static;

                }

                &:hover {
                    background-color: var(--background-second);
                    justify-content: space-between;

                    span {
                        color: var(--color-checked);
                    }

                    .item-btns {
                        display: block;
                    }

                    span {
                        width: 50%;
                    }

                    .item-btns-static {
                        display: none;
                    }
                }
            }
        }

        .list-btns {
            display: none;
            transition: all 0.4s ease-in-out;
            justify-content: space-between;
            padding-right: 5px;



            @media screen and (max-width:900px) {
                display: flex;
            }

            button {
                border: none;
                box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
                width: 3rem;
                height: 3rem;
                font-size: 16px;
                margin-top: 1px;
                border-radius: 5px;
                cursor: pointer;
                background-color: var(--background-first);
                color: var(--color-bg-wrap);

                &:hover {
                    background-color: var(--background-second);
                    color: var(--color-bg-wrap);
                }
            }
        }

        .listFooter {
            background-color: var(--background-header);
            width: 100%;
            font-size: 17px;
            height: 3.2rem;
            border-radius: 10px;
            text-align: center;
            color: black;

            .fff {
                position: relative;
                width: 3rem;
                z-index: 700;
                top: -32rem;
                left: 15px;
            }
        }

        &:hover {
            transform: translateY(-5px);
            box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;

            .list-btns {
                display: flex;
            }
        }


    }

    @media screen and (max-width:600px) {
        justify-content: center;
    }
}

.tbl-view-cat {

    max-width: 90%;
    margin: auto;

    .list-wrap {
        background-color: var(--background-wrap);
        color: var(--color-bg-wrap);
        width: 100%;
        min-width: 260px;
        height: fit-content;
        position: relative;
        border-radius: 10px;
        transition: all 0.4s ease-in-out;
        cursor: help;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .listHeader {
            padding: 0 15px;
            display: flex;
            justify-content: flex-start;
            padding: 0 15px;
            width: 100%;
            font-size: 14px;
            border-radius: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 500;
            border-bottom: 0.5px solid var(--border-fill);
            BACKGROUND: var(--background-first);
            COLOR: var(--color-bg-wrap);
            opacity: 0.8;
            margin-bottom: 5px;

            span {
                position: absolute;
                right: 10px;
            }

            svg {
                margin-right: 5px;
            }
        }

        .listBody {
            display: none;
        }

        .list-btns {
            display: none;
            transition: all 0.4s ease-in-out;
            justify-content: space-between;
            padding-right: 5px;



            @media screen and (max-width:900px) {
                display: flex;
            }

            button {
                border: none;
                box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
                width: 3rem;
                height: 3rem;
                font-size: 16px;
                margin-top: 1px;
                border-radius: 5px;
                cursor: pointer;
                background-color: var(--background-first);
                color: var(--color-bg-wrap);

                &:hover {
                    background-color: var(--background-second);
                    color: var(--color-bg-wrap);
                }
            }
        }

        .listFooter {
            background-color: var(--background-header);
            width: 100%;
            font-size: 17px;
            height: 3.2rem;
            border-radius: 10px;
            text-align: center;
            color: black;

            .fff {
                position: relative;
                width: 3rem;
                z-index: 700;
                top: -32rem;
                left: 15px;
            }
        }

        &:hover {
            transform: translateX(-10px);

            .list-btns {
                display: flex;
            }
        }


    }

    @media screen and (max-width:600px) {
        justify-content: center;
    }
}

.addNew {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.manager-menu {
    @media screen and (max-width:900px) {
        // align-items: baseline;
        flex-direction: row;
        justify-content: space-between;


    }


}