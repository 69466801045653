.div_width {
    width: 100%;

    button {
        padding-left: 3rem;
    }

}

.max_content {
    width: max-content;
    min-width: 21rem;
}

.catSelect {
    position: relative;
    font-size: 14px;
    cursor: pointer;


    .catSelectList {
        position: absolute;
        top: 34px;
        width: 200px;
        border: 1px solid var(--border-fill);
        text-align: left;
        background-color: var(--background-first);
        padding: 10px 0;
        padding-top: 0;
        z-index: 2000;
        color: var(--color-nav);
        height: 300px;
        overflow-y: auto;
        border-radius: 5px;

        .searchInput {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            position: relative;
            padding: 0;

            input {
                width: 100%;
                outline: none;
                border: none;
                background: transparent;
                margin: 0;
                color: var(--color-bg-wrap);
                font-size: 10px;
                color: var(--background-intense);
                background-color: var(--border-fill);
                // &::placeholder {
                //     font-style: italic;
                // }
            }

            svg {
                // background-color: 0;
                border: none;
                width: 2rem;
                font-size: 23px;
                top: -2px;

                &:hover {
                    color: var(--background-intense)
                }
            }

            &:hover {
                background-color: transparent;
                color: var(--background-intense)
            }

        }

        svg {
            position: absolute;
            right: -3px;
            top: 3px;
        }

        .svgactive {
            transform: rotate(90deg);
        }

        div {
            padding: 5px 10px;

            &:hover {
                background-color: var(--color-fill);
                color: var(--color-nav);
            }
        }
    }

    span {
        position: absolute;
        top: -6px;
        left: 5px;
        font-size: 8px;
        padding: 0 2px 0px 2px;
        background-color: var(--background-wrap);
        border-radius: 3px 3px 0 0;
        color: var(--color-bg-wrap);
        font-family: Roboto, Helvetica, Arial, sans-serif;
        font-weight: 400;
        font-size: 0.8rem;
    }

    option :hover {
        background-color: var(--color-fill);
    }


}

.setting_btn {

    transition: all 0.3s ease-in-out;
    text-align: left;


    &:hover {
        background-color: lavender;
        border-radius: 5px;
        // transform: scale(1.2);
        transform: translateX(5px);
        box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
    }

    width: 100%;
    height: 3rem;
    border: none;
    position: relative;
    top: -10px;
    left: -5px;
    width: 100%;
    font-size: 2rem;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--background-second);
    border-radius: 4px;
}

.firstSelect {
    background-color: var(--color-nav);
    color: white;
}

.cat-wrap {
    max-width: 90%;
    margin: auto;
    margin-top: 3rem;

    button {
        border-radius: 20px;
        width: 3rem;
        // height: 3rem;
        line-height: 0;
        border: none;
        margin-left: 5px;
        width: fit-content;
        transition: all 0.2s ease-in-out;
        background-color: var(--background-wrap);
        color: var(--color-bg-wrap);
        // ms-3 fs-2 mt-2 fst-italic
        margin-left: 1.5rem;
        font-size: 11px;
        position: relative;
        border: 1px solid var(--border-fill);
    }

    .cat-row {
        background-color: var(--background-wrap);
        color: var(--color-bg-wrap);
        // margin: 20px;
        width: 100%;
        min-width: 260px;
        height: fit-content;
        position: relative;
        // padding: 10px;
        border-radius: 5px;
        // box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        transition: all 0.4s ease-in-out;
        cursor: help;
        display: flex;
        flex-direction: column;
        justify-content: space-between;



        // button {
        //     height: 2.5rem;
        //     min-width: 2.5rem;
        //     line-height: 1;
        //     border-radius: 10px;
        //     transition: all 0.4s ease-in-out;
        //     background-color: var(--background-first);
        //     color: var(--color-bg-wrap);
        //     cursor: pointer;
        //     font-size: 12px;
        //     width: 2rem;
        //     height: 2rem;
        //     padding: 0;
        //     // display: none;

        //     &:hover {
        //         scale: 1.2;
        //         border-radius: 2px;
        //     }
        // }

        .cat-header {

            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 15px;
            width: 100%;
            font-size: 14px;
            border-radius: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 500;
            border-bottom: 0.5px solid var(--border-fill);
            BACKGROUND: var(--background-first);
            COLOR: var(--color-bg-wrap);
            opacity: 0.8;
            margin-bottom: 5px;
            padding: 5px 5px;

            .cat-name {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }

            .hidenBtn {
                display: none;
                margin-right: 5px;
            }

            svg {
                margin-right: 5px;
            }

            // color: var(--color-text);
        }

        input {
            border: none;
            outline: none;
            width: 100%;
            color: var(--color-checked);
            background-color: var(--color-fill);

            &:focus {
                border: none;
                outline: none;
                border-radius: 5px;
                color: (--background-intense);
            }
        }

        &:hover {

            // background-color: var(--color-fill);
            .cat-header {

                background-color: var(--color-fill);
            }

            .hidenBtn {
                display: block;
            }
        }

        .listBody {

            // box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
            // width: 100%;
            // display: flex;
            // flex-direction: column;
            // justify-content: flex-start;
            // align-items: flex-start;
            // height: 80%;
            // overflow-x: hidden;
            // scrollbar-color: var(--color-bg-wrap) var(--background-header);
            display: none;

            // .listItem {
            //     width: 100%;
            //     position: relative;
            //     // background-color: var(--background-first);
            //     color: var(--color-bg-wrap);
            //     padding: 1px;
            //     padding-left: 5px;
            //     text-align: start;
            //     display: flex;
            //     justify-content: flex-start;
            //     transition: all 0.4s ease-in-out;

            //     span {
            //         color: var(--color-bg-wrap);
            //         text-align: start;
            //         margin: unset;
            //         white-space: nowrap;
            //         overflow: hidden;
            //         text-overflow: ellipsis;
            //         max-width: 85%;
            //         width: fit-content;
            //     }

            //     .item-btns {
            //         display: none;
            //         border: none;
            //         transition: all 0.4s ease-in-out;
            //         width: 10rem;
            //         text-align: end;
            //         background: var(--background-header);
            //         height: 2.5rem;

            //         button {
            //             border: none;
            //         }
            //     }

            //     .item-btns-static {
            //         display: flex;
            //         font-size: 10px;
            //         color: GREY;
            //     }

            //     .item-btns-pb {
            //         @extend .item-btns-static;

            //     }

            //     // &::before {
            //     //     content: "-";
            //     //     position: absolute;
            //     //     left: 0px;
            //     // }

            //     // &::after {
            //     //     content: "";
            //     //     height: 100%;
            //     //     border-right: 0.8px solid;
            //     //     position: absolute;
            //     //     left: 0.5px;


            //     // }

            //     // &:last-child {
            //     //     &:after {
            //     //         height: 50%;
            //     //     }
            //     // }

            //     &:hover {
            //         background-color: var(--background-second);
            //         justify-content: space-between;

            //         span {
            //             color: var(--color-checked);
            //         }

            //         .item-btns {
            //             display: block;
            //         }

            //         span {
            //             width: 50%;
            //         }

            //         .item-btns-static {
            //             display: none;
            //         }
            //     }
            // }
        }

        .list-btns {
            display: none;
            transition: all 0.4s ease-in-out;
            justify-content: space-between;
            padding-right: 5px;



            @media screen and (max-width:900px) {
                display: flex;
            }

            button {
                border: none;
                box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
                width: 3rem;
                height: 3rem;
                font-size: 16px;
                margin-top: 1px;
                border-radius: 5px;
                cursor: pointer;
                background-color: var(--background-first);
                color: var(--color-bg-wrap);

                &:hover {
                    background-color: var(--background-second);
                    color: var(--color-bg-wrap);
                }
            }
        }

        .listFooter {
            background-color: var(--background-header);
            width: 100%;
            font-size: 17px;
            height: 3.2rem;
            border-radius: 10px;
            text-align: center;
            color: black;

            .fff {
                position: relative;
                width: 3rem;
                z-index: 700;
                top: -32rem;
                left: 15px;
            }
        }

        &:hover {
            transform: translateX(-10px);

            .list-btns {
                display: flex;
            }
        }


    }

    @media screen and (max-width:600px) {
        justify-content: center;
    }
}