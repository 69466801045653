.view-settings {
  bottom: 0px;
  right: 1px;
  display: flex;
  padding-left: 8px;
  // min-width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;

  @media screen and (max-width:900px) {
    justify-content: center;
    align-items: center;
    font-size: 14px;
    margin: 1.5rem 0;
    margin-bottom: 0;
    // margin-bottom: 0;
  }

  button {
    background-color: transparent;
    color: var(--color-bg-wrap);
    margin-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    max-width: fit-content;
    border-radius: 5px;


  }

  .viewBtn {
    margin-bottom: 3px;
  }

  .byCatBtn {
    border: none;
    height: 25px;
    margin: 0;

    svg {

      position: relative;
      top: 0px;
      left: 0px;
    }
  }

  .checked {
    color: #ed666d;
  }



}

.collect-path-box {
  display: flex;
  align-items: center;

  @media screen and (max-width:900px) {
    position: relative;
    left: -100px;
    // max-width: 230px;
  }

  @media screen and (max-width:600px) {

    max-width: 230px;
  }
}

.collect-btn-box {
  display: flex;
  align-items: center;

  @media screen and (max-width:900px) {
    position: absolute;
    top: 5px;
    right: 0;
  }
}

.one-collect-btn-box {
  display: flex;
  position: relative;

  @media screen and (max-width:900px) {
    margin-top: 1.5rem;
    // position: absolute;
    // top: 5px;
    // right: 0;
  }
}


.responsive-margin {
  @media screen and (max-width:900px) {
    margin-top: 0;

  }
}



.width150 {
  min-width: 160px;
}

.dropdownbtn {
  font-size: 11px;
  padding: 0;
}

.edit_btn_menu {
  display: flex;
  justify-content: flex-end;
  width: fit-content;

  button {
    width: 10rem;
    font-size: 1.1rem;
    margin-right: 1rem;
    border-color: var(--background-header);
    margin-bottom: 2rem;
    background-color: var(--background-wrap);
    color: var(--color-bg-wrap);

    @media screen and (max-width:900px) {
      font-size: 1rem;
      margin-bottom: 0;
      margin-top: 2rem;

    }


    &:hover {
      background-color: var(--color-bg-wrap);
      color: var(--background-wrap);
    }

    &:disabled {
      border-color: var(--background-header);
      color: #ddd1c2;

    }


  }

  @media screen and (max-width:900px) {
    width: 100%;
  }
}

.editBtnAbs {
  position: absolute;
  bottom: 11px;
  left: 25px;

  button {
    font-size: 1.2rem;
    margin-right: 1rem;
    background-color: var(--background-second);
    color: var(--color-bg-wrap);

    &:hover {
      color: var(--background-second);
      background-color: var(--color-bg-wrap);
    }

    @media screen and (max-width:600px) {
      font-size: 1rem;
      padding: 0.5rem;
      margin-left: 5px;
    }
  }

}

.input_with_lable {
  width: 100%;
  font-size: 18px;
  margin-bottom: 2rem;

  .lable {
    padding: 0 5px;
    margin: 0;
    min-width: 7%;
    border-radius: 5px;
  }

  input {
    border-color: var(--background-header);
    width: 80%;
    border: none;
    border-bottom: 1px solid var(--background-header);
    background-color: var(--background-second);
    color: var(--color-bg-wrap);
    text-align: center;

    &:focus {
      outline-color: var(--color-intense);
      border-bottom: 2px solid var(--background-header);
      text-align: start;
    }


  }

  @media screen and (max-width:900px) {
    font-size: 12px;
  }
}

.string_menu,
.string_menu_play {
  width: 100%;
  margin: 0 auto;
  min-height: 2vw;
  border: none;
  font-size: 14px;
  display: flex;
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
  position: sticky;
  top: 55px;
  background-color: var(--background-first);
  color: var(--color-bg-wrap);
  z-index: 700;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  h5 {
    position: absolute;
    font-style: italic;
    color: var(--color-checked);
    top: 50px;

    @media screen and ((max-width: 900px)) {
      position: initial;
    }
  }
}

.string_menu_play {
  position: relative;
  top: unset;
}

.box_menu {
  width: 222px;
  background-color: var(--background-wrap);
  color: var(--color-bg-wrap);
  border-radius: 10px;
  min-height: 3rem;
  padding: 10px;
  display: flex;
  flex-direction: column;

  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;


  @media screen and ((max-width: 600px)) {
    position: relative;
    top: 0;
    left: 0;
  }

  @media screen and ((max-height: 600px)) {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  span {
    margin-right: 3rem;

    &:hover {
      color: blue;
    }
  }

  .actionMenu {
    display: flex;
    width: 100%;
    flex-direction: column;
    vertical-align: middle;
    border-radius: 0.375rem;


    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;


    button {
      font-size: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 21rem;
    }

    @media screen and (min-width: 600px)and (max-height: 600px) {
      flex-direction: row;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      button {
        width: auto;
        justify-content: flex-start;
      }

    }
  }
}

.menu-title {
  background-color: var(--background-header);
  top: 4px;
  border-radius: 0 5px 5px 0;
  text-align: end;
  padding-right: 5px;
  left: -10px;
  width: 270px;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;

  position: relative;

}

.spanCat {
  position: absolute;
  margin: 0;
  top: -6px;
  right: 100%;
  font-size: 12px;
  padding: 0 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  right: 165%;
  top: 29px;
}

.name-collect {
  display: flex;
  position: relative;
  transition: all 0.4s ease-in-out;

  h1 {
    margin: 0;
  }

  span {
    margin-left: 5px;
  }

  .colname {
    margin-left: 5px;
    color: var(--color-intense);
    cursor: pointer;
  }

  &:hover {
    .colname {
      &::before {
        content: "click to edit";
        width: fit-content;
        white-space: nowrap;
        padding: 2px 5px;
        background-color: var(--background-second);
        opacity: 1;
        color: var(--color-text);
        border-radius: 5px;
        font-size: 9px;
        position: absolute;
        bottom: -20px;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        animation: fadeOut 4s forwards;
        z-index: 2000;
      }
    }
  }
}

.menufind {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 2rem;
  width: 100%;
  flex-wrap: wrap;
  background-color: var(--background-first);

  h1 {
    // background: var(--background-header);
    padding: 2px 5px;
    border-radius: 9px;
    color: var(--color-bg-wrap);
    font-size: 1.2rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    @media screen and (max-width:900px) {
      font-size: 1.5rem;
    }
  }



  .findWidth {
    width: 25%;

    input {
      background-color: var(--background-second);
      color: var(--color-nav);

    }


    @media screen and (max-width: 600px) and (min-height: 600px) {
      width: 100%;
    }
  }

  @media screen and (max-width:900px) {
    justify-content: center;
    flex-direction: column;
    margin-right: 0;
    // margin-top: 1rem;
  }
}

.collect-find {
  border-radius: 15px;



  button {
    background-color: var(--background-wrap);
    color: var(--color-bg-wrap);

    &:hover {
      background-color: var(--background-intense);
      color: white;
    }
  }

  @media screen and (max-width: 900px) {
    margin: auto;
  }


  input,
  span {
    border: none;
    background-color: var(--background-second);
    color: var(--color-bg-wrap);

    &:focus {
      color: var(--color-checked);
      background-color: var(--background-second);

    }

    @media screen and (max-width: 900px) {
      font-size: 10px;
    }
  }

  input {
    width: 0%;
  }

}



.inputCheck {
  font-size: 16px;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 1rem;

  label {
    display: flex;
    font-size: small;
    font-size: 16px;

    span {
      border: 1px solid black;
      width: 2rem;
      height: 2rem;
      position: absolute;
      left: 8px;
      top: 5px;
      line-height: 0.9;

      &:hover {
        color: black;
      }
    }


  }

  .heart {
    color: #ed666d;

    &:hover {
      color: #ed666d;
    }

  }

  input {
    margin-right: 1rem;
    height: 3rem;
    width: 3rem;
    opacity: 0;

    &:checked+label {
      span {
        box-shadow: inset 3px 3px 5px #c5c5c5,
          inset -3px -3px 5px #ffffff;
      }
    }
  }
}



.select_wrap {
  width: fit-content;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width:900px) {
    flex-wrap: wrap;
    align-items: stretch
  }
}

.menuCollection {
  /* d-flex align-items-center justify-content-end me-2 */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 2rem;
  width: 70%;
  width: 100%;

  @media screen and (min-width: 600px) and (max-height: 600px) {
    flex-wrap: wrap;
  }


  @media screen and (max-width: 600px) and (min-height: 600px) {

    flex-wrap: wrap;
    align-items: baseline;
    justify-content: center;
  }

}

.menuBtn {
  width: 100%;
  min-width: 150px;
  background-color: var(--background-wrap);
  color: var(--color-bg-wrap);

  &:hover {
    background-color: var(--background-header);
  }
}

.divider {
  border-bottom: 1px solid;

  @media screen and ((max-width: 600px)) {
    display: none;
  }
}

#sortBtn {
  &:hover {
    svg {
      animation: swing 0.8s forwards;
    }
  }
}

.active-border {
  border: 2px solid #FF4500;
  border-radius: 5px;
  // padding: 5px 10px;
}

.organiser-box,
.organiser-box-btn,
.organiser-box-title {
  // border: 2px solid #FF4500;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  justify-content: flex-start;
  justify-content: space-between;

  h3 {
    color: var(--color-checked);
    position: absolute;
    font-size: 20px;
    font-weight: 700;
    right: 15px;
    bottom: 5px;
  }

  p {
    text-align: left;
    padding-left: 15px;
  }

  span {
    background-color: var(--color-checked);
    padding: 0 6px 2px 6px;
    color: white;
    border-radius: 5px 0% 50% 0;
    font-weight: 700;
    position: absolute;
    left: 0;
    top: 0;

  }

  @media screen and ((max-width: 900px)) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;

    justify-content: flex-start;

    .btn {
      width: 100%;
    }
  }
}

.organiser-box-btn {

  .btn {
    border: 1px solid var(--border-fill);
  }

  @media screen and ((max-width: 900px)) {


    justify-content: space-between;

    .btn {
      width: fit-content;
    }
  }
}

.organiser-box-title {
  background-color: var(--background-second);
}

.organiser-wrap {
  border: 3px solid var(--color-intense);
  padding: 5px 10px;

  svg {
    width: 3rem;
    height: 3rem;

    &:hover {
      color: var(--color-nav);
    }
  }

  @media screen and (max-width:900px) {
    font-size: 12px;
  }
}

.collection-path {
  span {
    cursor: pointer;
  }
}

.spin-btn-child {
  display: flex;
  flex-direction: column;

  div {
    font-size: 15px;
    text-wrap: nowrap;

    svg {
      margin-right: 5px;
    }

    &:hover {
      color: var(--color-nav);
    }
  }
}

.playlists-menu-btn {
  @media screen and (max-width:900px) {
    // align-items: baseline;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;

  }
}