@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kurale&display=swap');

.topNav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2500;
}

.headerNav {
  width: 100%;
  background-color: var(--background-nav);
  // padding: 5px;
  height: 49px;
  color: white;
  text-align: start;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-end;

  @media screen and (max-width:600px) {
    height: 100px;
  }

  @media screen and (max-width:900px) {
    height: 60px;
  }

  h1 {
    font-size: 4rem;
    margin-right: 2rem;
    margin-bottom: -2.5rem;

    @media screen and (max-width:600px) {
      font-size: 26px;
    }

    @media screen and (max-width:900px) {
      font-size: 26px;
    }
  }

  span {
    font-family: 'Kurale', serif;
    text-align: end;
    font-size: 24px;
    // font-style: italic;

    @media screen and (max-width:600px) {
      font-size: 16px;
    }

    @media screen and (max-width:900px) {
      font-size: 16px;
    }
  }
}

.headerNavSmall {
  @extend .headerNav;
  height: 25px;
  padding: 5px;
  display: flex;

  align-content: center;

  @media screen and (max-width:900px) {
    width: 100vw;

  }

  h1 {
    font-size: 1.1rem;
    margin-right: 1rem;
    margin-bottom: 0rem;

    @media screen and (max-width:900px) {
      font-size: 17px;
    }


  }
}

.navbarico {
  width: 30px;
  height: 30px;
  position: fixed;
  top: 5px;
  left: 0;
}

.navWrap {
  width: 100%;
  z-index: 901;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-family: Inter;
  margin: auto;
  padding: 5px 10px;
  padding-right: 0;
  border-radius: 5px;
  // box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  background-color: var(--background-first);
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 0.5px solid var(--border-fill);

  @media print {
    display: none;
  }

  @media screen and (max-width: 900px) {
    padding: 5px 10px;

  }

  a,
  span {
    text-decoration: none;
    color: var(--color-nav);
    font-weight: 500;
    margin-right: 13px;
    margin-right: 25px;




    @media screen and (max-width: 900px) {
      margin-right: 12px;
      font-size: 12px;
      text-wrap: nowrap;
    }


  }

  .active {
    color: var(--color-intense);
    // text-decoration: underline;
    position: relative;
    cursor: pointer;
    font-weight: 800;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }
}

.leftnav {
  display: flex;

  @media screen and (max-width: 900px) {
    justify-content: flex-start;
    width: 75%;
  }
}

.dropdown {
  padding: 0;
  font-size: inherit;
  height: auto;
  margin-right: 25px;
  color: var(--color-nav);
  position: relative;
  cursor: pointer;

  @media screen and (max-width: 900px) {
    margin-right: 12px;
    margin-right: 3px;
    font-size: 11px;
    text-wrap: nowrap;

    svg {
      width: auto;
    }
  }



  img {
    width: 8%;
    height: 8%;
    width: 25px;
    height: 25px;
    margin-left: -1rem;
    border-radius: 50%;
  }

  svg {
    margin-left: 3px;

  }

  .subbox,
  .subboxOnBtn {
    // display: none;
    flex-direction: column;
    align-items: flex-start;
    height: fit-content;
    width: fit-content;
    position: absolute;
    right: 0px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
    font-size: 1.2rem;
    padding: 5px 0px;
    background-color: var(--background-first);
    text-align: left;
    color: var(--color-nav);


  }

  .subbox {
    // display: none;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 0s 3s;
  }

  .subboxOnBtn {

    display: flex;
    z-index: 1105;

  }

  a,
  span {
    text-decoration: none;
    color: var(--color-nav);
    font-weight: 500;
    margin: 5px;
  }

  .subrow,
  .activesubrow,
  span,
  a {
    padding: 5px 30px;
    text-align: start;
    width: 100%;
    margin: 0;

    &:hover {
      background-color: var(--color-fill);
      color: var(--color-nav);
    }
  }

  .activesubrow {
    // background-color: var(--color-nav);
    position: relative;
    cursor: pointer;
    text-align: start;
    padding: 5px 30px;
    margin: 0;
    font-weight: 800;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  }

  &:hover {

    div {
      display: flex;
      z-index: 1105;
      opacity: 1;
      visibility: visible;
      transition-delay: 0.8s;
    }

  }
}



.logout {
  font-size: large;
  height: 100%;
  border: none;
  font-weight: 500;

  @media screen and (max-width: 900px) {
    margin-right: 0px;
    font-size: 12px;
    padding: 3px;
  }

}

.subnavWrap {
  background-color: var(--background-header);
  width: 100%;
  height: 3rem;
  display: flex;

  h1 {
    padding: 2px 5px;
    border-radius: 9px;
    color: white;
  }

  button {

    width: 2.5rem;
    height: 2.5rem;
    font-size: 15px;
    margin: 2px 2px;
    background-color: var(--background-wrap);

    color: #646f7d;
    color: var(--color-bg-wrap);
    padding: 0;
    border-radius: 5px;
    border: none;
    position: relative;
  }
}

@media screen and (min-width: 600px) {
  .nav {
    font-size: large;
  }
}

@media screen and (max-width: 600px) {
  .nav {
    position: relative;
    font-size: small;
    padding-right: 0rem;
    width: fit-content;
    flex-wrap: wrap;
  }
}