@import 'animate.css';

.wrap,
.wrapS {

    box-sizing: border-box;
    width: 100px;
    height: 35px;
    background-color: var(--background-wrap);
    color: var(--color-bg-wrap);
    font-size: 1.3rem;
    display: flex;
    position: relative;
    margin-right: 3rem;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 15px;



    @media screen and (max-width: 900px) {
        margin-right: 0px;
        margin-top: 5px;
        width: 70px;
        height: 25px;
        font-size: 1rem;
    }



    .left,
    .leftEl {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        box-sizing: border-box;
        width: 100%;

        background-color: var(--background-nav);
        color: white;
        border-radius: 10px 0 0 10px;

        @media screen and (max-width:900px) {
            font-size: 8px;
        }
    }



    .right,
    .rightEl {
        @extend .left;
        border-radius: 0 10px 10px 0;
    }

    .choise {
        @extend .left;
        width: 48%;
        height: 90%;
        box-sizing: border-box;
        position: absolute;
        top: 5%;
        left: 1%;

        background-color: var(--background-header);
        border-radius: 10px 0 0 10px;
        transition: all 0.4s ease-in-out;
        color: rgb(77, 77, 99);
        font-weight: 600;
        border: outset white;
        color: var(--color-nav);
    }

    .l {
        left: 51%;
        border-radius: 0 10px 10px 0;
    }
}

.wrapS {
    height: 25px;
    width: 90%;
    margin: auto;
    font-size: 1rem;

}

.btnBar {
    span {
        display: flex;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    }

    .activeSpan {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
        color: orangered;
    }
}

.viewBtn,
.btnBar,
.drop-down-menuBtn {

    width: 35px;
    height: 35px;


    background-color: transparent;
    border-radius: 50%;
    border: none;
    font-size: 18px;

    color: var(--color-bg-wrap);
    z-index: 900;
    transition: all 0.2s ease-in-out;
    background: var(--color-fill);
    border: 1px solid var(--border-fill);

    @media screen and (max-width:900px) {
        margin-bottom: 0px;
    }

    &:hover {
        background-color: var(--border-fill);
        color: var(--color-nav);
        border: 1px solid var(--color-fill);
    }

}

.viewBtn {
    position: relative;
    margin-right: 10px;
    transition: all 0.4s ease-in-out;
    color: var(--white-black);

    svg {
        position: relative;
        top: -2px;
        left: 0px;
    }

    &:hover {
        &::before {
            content: attr(data-title);
            width: fit-content;
            white-space: nowrap;
            padding: 2px 5px;
            background-color: var(--background-second);
            opacity: 1;
            color: var(--color-text);
            border-radius: 5px;
            font-size: 9px;
            position: absolute;
            bottom: -20px;
            box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
            animation: fadeOut 4s forwards;
            z-index: 2000;
        }
    }
}

.drop-down-menuBtn {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.2s ease-in-out;
    margin-right: 10px;

    .iconDr,
    .iconDrRot {
        margin: auto;
        transition: all 0.4s ease-in-out;
    }



    .buttonBox {
        border: 1px solid;
        border-radius: 25px;
        background-color: var(--background-wrap);
        color: var(--color-bg-wrap);
        // display: none;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 0px;
        // animation: fadeIn 0.7s forwards;
        border: 1px solid var(--border-fill);
        text-align: center;
        height: 0;
        transition: all 0.4s ease-in-out;
        display: flex;
        flex-direction: column;

        .viewBtn {
            margin-bottom: 0;
            margin-top: 0;
            margin-right: 0px;

            &:hover {
                &::before {
                    top: unset;
                    bottom: unset;
                    right: 40px;
                    height: fit-content;
                }
            }
        }
    }

    &:hover {
        align-items: flex-start;

        .iconDr {
            font-size: 5px;
        }

        .iconDrRot {

            transform: rotate(45deg);
        }

        .buttonBox {
            visibility: visible;
            opacity: 1;
            z-index: 900;

            height: fit-content;
        }
    }
}

.checked {
    color: #FF4500;
}

.checkedR {
    color: #0d6af5;
    rotate: 90deg;
    transition: all 0.2s ease-in-out;


}

.wrapBox {
    box-sizing: border-box;
    width: 50px;
    height: 25px;
    background-color: #EBEBEB;
    font-size: 1.2rem;
    display: flex;
    position: relative;
    margin-right: 3rem;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 15px;



    @media screen and (max-width: 900px) {
        margin-right: 0px;
        margin-top: 5px;
        width: 50px;
        height: 25px;
        font-size: 1rem;
    }

    .leftPart {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        box-sizing: border-box;
        width: 50%;
        background-color: #EBEBEB;
        color: #005FA1;
        border-radius: 10px 0 0 10px;
    }



    .rightPart {
        @extend .leftPart;
        border-radius: 0 10px 10px 0;
    }

    .choise {
        @extend .left;
        width: 48%;
        height: 94%;
        font-size: 1.2rem;
        box-sizing: border-box;
        position: absolute;
        top: 2%;
        left: 1.5%;

        background-color: var(--background-header);
        border-radius: 10px 0 0 10px;
        transition: all 0.4s ease-in-out;
        color: rgb(77, 77, 99);
        color: white;
        font-weight: 600;
        border: outset white;


    }

    .l {
        top: 2%;
        left: 51%;
        border-radius: 0 10px 10px 0;


    }
}





/* The switch - the box around the slider */


.themeSwitch {
    font-size: 12px;
    position: absolute;
    right: 0;
    display: inline-block;
    width: 2.5em;
    height: 2em;
    z-index: 1000;
    bottom: unset;
    scale: 0.8;

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        --background: #28096b;
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--background);
        transition: .5s;
        border-radius: 30px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 1.4em;
        width: 1.4em;
        border-radius: 50%;
        left: 22%;
        bottom: 15%;
        box-shadow: inset 8px -4px 0px 0px #fff000;
        background: var(--background);
        transition: .5s;
    }

    input:checked+.slider {
        background-color: #2196f3;
    }

    input:checked+.slider:before {
        // transform: translateX(100%);
        box-shadow: inset 15px -4px 0px 15px #fff000;
    }
}

.game {
    // bottom: 0;
    right: 0;
}