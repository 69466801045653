.mainbox {
    box-sizing: border-box;
    position: relative;
    width: 200px;
    height: 25px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    border-radius: 160px;
    transition: all 0.3s ease;
    border: 1px solid rgb(216, 216, 218);
    justify-content: center;

    .icon {
        box-sizing: border-box;
        padding-top: 0px;
        width: fit-content;
        transition: all 0.3s ease;
        color: var(--color-nav);

        svg {
            box-sizing: border-box;
            fill: white;
            font-size: 1.3em;
        }
    }

    input {
        box-sizing: border-box;
        height: 100%;
        width: 170px;
        background-color: transparent;
        border: none;
        outline: none;
        padding-bottom: 4px;
        padding-left: 10px;
        font-size: 1em;
        color: var(--color-nav);
        transition: all 0.3s ease;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    @media screen and (max-width:900px) {
        width: 170px
    }
}

.secondarybox {
    box-sizing: border-box;
    position: relative;
    width: 200px;
    height: 25px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    border-radius: 160px;
    transition: all 0.3s ease;
    border: 1px solid rgb(216, 216, 218);
    justify-content: center;

    span {
        position: absolute;
    }

    .icon {
        box-sizing: border-box;
        padding-top: 0px;
        width: fit-content;
        transition: all 0.3s ease;
        color: var(--color-nav);
        padding-right: 5px;

        svg {
            box-sizing: border-box;
            fill: var(--color-fill);
            font-size: 1.3em;
        }
    }

    input {
        box-sizing: border-box;
        height: 100%;
        width: 170px;
        background-color: transparent;
        border: none;
        outline: none;
        padding-bottom: 4px;
        padding-left: 10px;
        font-size: 1em;
        color: var(--color-nav);
        transition: all 0.3s ease;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    @media screen and (max-width:900px) {
        width: 170px
    }
}

.short {
    position: relative;

    .mainbox,
    .secondarybox {
        width: 25px;
        justify-content: space-around;

        .icon {
            padding-right: 6px;
            left: -7px;
            position: relative;
        }

        input {
            width: 0;
            height: 0px;
        }
    }

    .secondarybox {
        .icon {
            padding: 0;
            position: relative;
            right: 2px;
        }
    }
}

.hideFilter {
    visibility: hidden;
    width: 0;
}