.card-and-arrow {
    margin-top: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    @media screen and (max-width: 600px) and (min-height: 600px) {
        align-items: flex-start;
    }
}

.textContent {
    width: 100%;
    height: 95%;
    display: flex;
    justify-content: center;
    align-items: center;


    @media screen and (max-width: 900px) {
        flex-direction: column;
        justify-content: flex-start;

    }


    .textContentImg {
        width: 50%;
        height: 95%;
        display: flex;
        justify-content: center;
        align-items: center;


        @media screen and (max-width: 900px) {
            flex-direction: column;
            justify-content: flex-start;

        }
    }

    .wh-75 {
        width: 50%;

        @media screen and (min-width: 600px) and (max-height: 600px) {
            width: 75%;
            height: 50%;
        }

        @media screen and (max-width: 600px) and (min-height: 600px) {
            width: 100%;
            height: 75%;
        }
    }

    img {
        max-width: 50%;
        max-height: 100%;

        @media screen and (min-width: 600px) and (max-height: 600px) {
            max-width: 25%;
            max-height: 100%;
        }

        @media screen and (max-width: 600px) and (min-height: 600px) {
            max-width: 100%;
            max-height: 25%;
        }
    }

    .mwh-100 {
        max-width: 100%;
        max-height: 100%;
    }

    .img_float {
        float: left;

        @media screen and (max-width: 600px) {
            float: unset;
        }
    }



}

.card-text-sibl {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 20px;
    height: 90%;

}

.imgContent {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container_galleryAbout {
    width: 80rem;
    height: 45rem;
    margin-bottom: 5rem;
    margin-right: auto;
    margin-left: auto;

    @media screen and (min-width: 800px) and (max-height: 391px) {
        width: 40rem;
        height: 20rem;
    }

    @media screen and (max-width: 600px) and (min-height: 600px) {
        width: 30rem;
        height: 32rem;
        margin-left: 1rem;
    }

    @media screen and (max-width: 300px) and (min-height: 600px) {
        width: 20rem;
        height: 25rem;
    }

    @media screen and (max-width: 1000px) and (max-height: 600px) {
        width: 50rem;
        height: 25rem;
        margin: 0;
    }
}



.container_gallery,
.container_gallery_view,
.container_gallery_result {
    width: 75rem;
    height: 35rem;
    margin-bottom: 5rem;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 1rem;

    @media screen and (max-width: 1600px) and (max-height: 700px) {
        margin-bottom: 1rem;

    }

    @media screen and (min-width: 800px) and (max-height: 391px) {
        width: 75rem;
        height: 35rem;

    }


    @media screen and (min-width: 800px) and (max-height: 391px) {
        width: 40rem;
        max-height: 20rem;

    }

    @media screen and (max-width: 600px) and (min-height: 600px) {
        width: 30rem;
        max-height: 45rem;

    }

    @media screen and (min-width: 600px) and (max-height: 600px) {
        max-width: 80vw;
        max-height: 70vh;
        margin-bottom: 3rem;

    }

    @media screen and (max-width: 300px) and (min-height: 600px) {
        width: 20rem;
        max-height: 35rem;

    }
}



.container_gallery_result {
    padding-bottom: 4rem;

    @media screen and (min-width: 600px) and (max-height: 600px) {
        margin: auto;

    }
}

.container_gallery_view {
    margin-bottom: 4rem;

    @media screen and (max-width: 1600px) and (max-height: 700px) {
        width: 100rem;
        height: 45rem;

    }
}

.container_gallerymini {
    width: 37rem;
    height: 17rem;
    margin: auto;
    margin-bottom: 1rem;
}


.collect_button {
    height: 60px;
    width: 60px;
    z-index: 5;
    font-size: 4rem;
    border-radius: 30px;
    border: solid 1px black;
    padding: 0px 12px;
    text-decoration: none;
    cursor: pointer;
    overflow: hidden;
    font-family: Arial;
    line-height: 1;

    @media screen and (max-width: 600px) and (min-height: 600px) {
        height: 45px;

    }

    &:hover {
        background-color: black;
        color: white;
    }

    &:disabled {
        text-shadow: 1px 1px 0px #ffffff;
        color: #777777;
        box-shadow: inset 1px 1px 2px 0px #ffffff;
        background: linear-gradient(180deg, #ededed 10%, #dfdfdf 100%);
    }

    .collect_button_text {
        position: relative;
        display: inline-block;

    }
}

.card-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    position: relative;

    .leftbtn,
    .rightBtn {
        z-index: 3;
        transition: 0.8s ease;
        font-weight: bold;
        position: absolute;
        bottom: 0;
        height: 60px;
        width: 60px;
        z-index: 5;
        font-size: 4rem;
        border-radius: 0 70% 0 0;
        border: none;
        text-decoration: none;
        cursor: pointer;
        overflow: hidden;
        font-family: Arial;
        line-height: 2;


        transition: all 0.5s ease;

        background: var(--color-fill);
        color: var(--color-intense);

        .hide {
            opacity: 0;
        }

        &:disabled {
            background: grey;
            opacity: 0;
        }

        &:hover {

            transform: translate(-5px, 5px);
            border-radius: 0 17% 0 17%;
        }

        @media screen and (min-width:1700px) and (max-height: 600px) {
            height: 45px;
            width: 45px;
        }

        div {
            margin: auto;
        }
    }

    .rightBtn {
        right: 0;
        border-radius: 70% 0 0 0;

        &:hover {
            border-radius: 17% 0 0 17%;
            transform: translate(5px, 5px);
        }
    }
}

.card-containermini {

    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: auto;
    margin: auto;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 15px;
}

.cardFontSize_0 {
    font-size: 8rem;
}

.cardFontSize_1 {
    font-size: 4rem;
}

.cardFontSizemini {
    font-size: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
}

.wordBreak {
    word-break: break-word;
    hyphens: auto;
}

.card-button {
    padding: 0;
    margin: 0;
    border: none;
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: relative;
    background: transparent;
    transform-style: preserve-3d;
    perspective: 5000px;
    border-radius: 15px;


    @media screen and (max-width: 600px) {
        height: auto;
        height: inherit;
    }


}

.result-again,
.result-againer {
    min-width: 16rem;

    height: fit-content;
    font-size: 3rem;
    margin: auto;
    margin-top: 5rem;
    border: 1px solid black;
    border-radius: 15px;
    padding: 5px;
    background-color: var(--background-second);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
    transition: all 0.4s ease-in-out;

    @media screen and (max-width: 600px) {
        width: fit-content;
        min-width: unset;
        font-size: large;
        scale: 0.8;
    }

    @media screen and (max-height: 400px) {
        width: fit-content;
        min-width: unset;
        font-size: large;
        scale: 0.8;
        margin-top: 1rem;
    }

    &:hover {
        // background-color: var(--background-third);
        background-color: var(--color-nav);
        color: white;
        transform: scale(1.2);
        box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
    }
}

.result-againer {
    margin-top: 0rem;

    @media screen and (max-width: 600px) {

        margin-top: 2rem;
    }
}

.card-front,
.card-back {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    border-radius: 15px;
    padding-right: 2vw;
    padding-left: 2vw;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
    scrollbar-color: var(--color-bg-wrap) var(--background-header);
    color: var(--color--card);
    overflow: hidden;

    .div-note {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-content: center;

    }

    .card-note {
        font-size: small;
        text-align: end;
        top: 0;
        font-size: 16px;
        left: 0;
        transition: all 0.2s ease-in-out;
        text-wrap: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: var(--color-intense);
        width: 100%;
        text-align: left;
        width: 100%;
        height: 30px;
        transition: all 0.2s ease-in-out;

        &::before {
            content: "";
        }

        &:hover {
            color: transparent;
            text-wrap: wrap;


            &::before {
                display: block;
                content: attr(data-title);
                position: absolute;
                height: fit-content;
                padding: 10px;
                width: 100%;
                top: 0;
                left: 0;
                color: white;
                font-size: 18px;
                border-radius: 0 0 15px 15px;
                // background-color: var(--color-checked);
                background-color: var(--background-note);
                ;
            }
        }
    }

    .mwh {
        max-width: 100%;
        height: fit-content;
        max-height: 100%;
    }
}

.card-front {
    background: var(--background-first);
    color: var(--color-bg-wrap);
    position: absolute;
    top: 0;
    left: 0;
    font-size: 24vw;

}

.card-result {
    @media screen and (max-width: 600px) {
        position: relative;
        height: 54vh;
        height: 32vh;
        overflow: hidden;
    }
}

.card-back {

    background: var(--background-second);
}

.hint {
    text-align: end;

}

.progr {
    position: absolute;
    top: -8px;
    z-index: 901;
    width: 100%;
}

@media screen and (min-width: 800px) and (max-height: 391px) {

    .cardFontSize_1 {
        font-size: 2.5rem;
    }

    .cardFontSize_0 {
        font-size: 3.5rem;
    }



}

@media screen and (max-width: 600px) and (min-height: 600px) {




    .cardFontSize_1 {
        font-size: 2.5rem;
    }

    .cardFontSize_0 {
        font-size: 3.5rem;
    }

    .cardFontSizemini {
        font-size: 1rem;

    }

    .container_gallerymini {
        width: 15rem;
        height: 6rem;
        margin-bottom: 1rem;
        margin-right: auto;
        margin-left: auto;
    }
}

@media screen and (max-width: 300px) and (min-height: 600px) {




    .cardFontSize_1 {
        font-size: 2rem;
    }

    .cardFontSize_0 {
        font-size: 3.5rem;
    }

}

@media screen and (max-width: 1000px) and (max-height: 600px) {




    .cardFontSize_1 {
        font-size: 2rem;
    }

    .cardFontSize_0 {
        font-size: 3.5rem;
    }


}