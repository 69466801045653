@import 'animate.css';

.games-menu-wrap {
    position: relative;
    min-height: 44px;
    min-width: 44px;
}

.games-menu-wrap-vert {
    position: relative;
    min-height: 24px;
    min-width: 70px;
    font-size: 20rem;
    // box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    border-radius: 5px;
    // display: flex;
    justify-content: space-between;
    align-items: center;

    button {
        max-width: 145px;
        justify-content: flex-start;
        // color: white;
    }

    .games-menu-btn {

        background-color: transparent;
        text-align: start;
        border: none;
        font-size: 14px;
        line-height: 0.7;
        color: var(--color-menuG);
        z-index: 1101;
        position: absolute;
        transition: all 0.4s ease-in-out;
        top: 0px;
        left: 0;
        border-radius: 5px;
        padding: 0;
        justify-content: flex-start;
        max-width: unset;

        .ico {
            font-size: 14px;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            transition: all 0.4s ease-in-out;
        }

        &:hover {
            background-color: var(--back-menu);
            background-color: var(--color-fill);
            border: 0.5px solid var(--border-fill);
            z-index: 1101;
            // color: white;
            border-radius: 5px;
        }
    }

    .games-menu-btn-show {
        @extend .games-menu-btn;
        width: 145px;
        max-width: 145px;
        background-color: var(--back-menu);
        background-color: var(--color-fill);
        border-radius: 5px;
        border: 1px solid var(--color-intense);
        z-index: 1101;
        text-align: start;
        justify-content: flex-start;
        // color: white;



        .ico {
            color: yellow;
            rotate: 360deg;
            stroke: black;
            stroke-width: 0.4px;
            margin: 5px;
            border: 1px solid black;
        }

        &:hover {
            border-radius: 5px;

        }
    }

}





.games-menu-item {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin: 0 5px;
    background-color: transparent;
    // border-radius: 50%;
    border: none;
    font-size: 14px;
    color: #5f6368;
    color: var(--color-bg-wrap);
    padding: 2px 0;
    transition: all 0.4s ease-in-out;
    width: 100%;


    svg {
        stroke: black;
        stroke-width: 0.3px;
    }

    &:hover {
        // transform: translateX(-5px);
        color: var(--color-intense);
        color: var(--color-rate);
        border-bottom: 1px solid var(--color-nav);
    }
}

.games-menu-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    top: 0;
    left: -10px;
    height: -moz-fit-content;
    height: fit-content;
    // background-color: var(--color-nav);
    background-color: var(--back-menu);
    color: white;
    margin: 3px 10px;
    padding: 0 5px;
    text-align: start;

    border-radius: 10px;
    border: 1px solid var(--color-intense);
    transition: all 0.4s ease-in-out;
    width: fit-content;
    z-index: 1100;
}

.overflow-vert {
    position: absolute;
    overflow: hidden;
    height: 30px;
    border-radius: 5px;
    width: 70px;
    transition: all 0.4s ease-in-out
}

.overflow-vert-show {
    @extend .overflow-vert;
    width: 145px;
    height: 230px;
}

.body-after-btn {
    @extend .games-menu-body;
    background-color: var(--color-fill);
    color: var(--white-black);
    position: absolute;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    height: fit-content;
    padding-top: 40px;
    padding-bottom: 5px;
    top: -5px;
    align-items: flex-start;
    width: 145px;

}

.games-menu-body-horizontal {
    @extend .games-menu-body;
    flex-direction: row;
    bottom: -3px;
    top: unset;
    left: -10px;
    right: unset;
    width: 260px;
    background-color: var(--background-header);
    color: var(--color-bg-wrap);
    border: none;
    padding-bottom: 1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    .games-menu-item {
        text-align: center;
        font-size: 20px;
        border-radius: 50%;
        // color: var(--color-fill);

        svg {
            stroke: unset;
        }

        &:hover {
            transform: scale(0.8);
            color: var(--color-bg-wrap);

            padding: 1px;
            border-bottom: none;
            box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, .5),
                -4px -4px 6px 0 rgba(116, 125, 136, .5),
                inset -4px -4px 6px 0 rgba(255, 255, 255, .2),
                inset 4px 4px 6px 0 rgba(0, 0, 0, .4);

        }
    }
}


.body-after-btn-horizontal {
    z-index: 3;
    @extend .body-after-btn;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 44px;
    bottom: unset;
    top: -4.5px;
    left: 1px;
    right: unset;
    width: 250px;
    height: 44px;
    align-items: end;



    &::before {
        content: "";
        position: absolute;
        left: -10px;
        top: 0.5px;
        width: 44px;
        height: 44px;
        border: 20px solid #fff;
        box-sizing: border-box;
        border-radius: 50%;
        z-index: 1;
    }

    .games-menu-item {
        margin: 2px 4px;
    }
}

.dropdown-toggle {
    position: relative;
    border: none;
    width: 8rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;


    &:hover {

        background-color: var(--color-fill);
        border: 0.5px solid var(--border-fill);
    }

    svg {
        margin-right: 5px;
        font-size: 20px;



    }
}

.dropdown-menu {
    position: absolute;
    list-style: none;
    text-align: left;
    cursor: pointer;
    background-color: var(--color-fill);
    width: 14rem;
    z-index: 1100;
    margin-top: 1rem;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

    li {
        margin: 3px 0;
        font-size: small;

        &:hover {
            background-color: var(--color-nav);
            color: white;
        }
    }

    @media screen and (max-width:900px) {
        font-size: 14px;

    }

}