.infoCards {
	padding-top: 15rem;

	&:hover {
		-webkit-transform: scale(1.05);
		transform: scale(1.05);
		-webkit-transition: .3s ease-in-out;
		transition: .3s ease-in-out;
	}
}

.yellow {
	position: absolute;
	line-height: 1;
	font-family: cursive;
	border-radius: 50%;
	background-color: #FDDC58;
	rotate: 10deg;
	top: 0px;
	// left: -20px;
	height: 150px;
	width: 150px;
	font-size: 4rem;
	color: black;
	display: flex;
	align-items: center;
	font-size: 2rem;
	padding: 10px;
	transition: all 0.2s ease-in-out;
	z-index: 6;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

	@media screen and (max-width:600px) {
		height: 70px;
		width: 70px;
		font-size: 1rem;

	}

	@media screen and (max-width:900px) {
		height: 70px;
		width: 70px;
		font-size: 1rem;
		right: 20px;
		top: 0px;
	}

	// @media screen and (max-height: 600px) {
	// 	left: 20%;
	// 	top: 180px;
	// }

	@media screen and (max-width:1200px) {
		// height: 70px;
		// width: 70px;
		// font-size: 1rem;
		// left: 10px;
		// top: 130px;
	}

	// @media screen and (max-height:500px) {
	// 	// height: 70px;
	// 	// width: 70px;
	// 	// font-size: 1rem;
	// 	right: 10px;
	// 	left: unset;
	// 	top: 130px;
	// }

	&:hover {
		scale: 1.2;
	}
}

.collect_button {
	height: 60px;
	width: 60px;
	z-index: 5;
	font-size: 4rem;
	border-radius: 30px;
	border: solid 1px black;
	padding: 0px 12px;
	text-decoration: none;
	cursor: pointer;
	overflow: hidden;
	font-family: Arial;
	position: absolute;
	bottom: -30px;
	right: 45%;
	line-height: 1;

	&:hover {
		background-color: black;
		color: white;
	}

	&:disabled {
		text-shadow: 1px 1px 0px #ffffff;
		color: #777777;
		box-shadow: inset 1px 1px 2px 0px #ffffff;
		background: linear-gradient(180deg, #ededed 10%, #dfdfdf 100%);
	}

	.collect_button_text {
		position: relative;
		display: inline-block;

	}
}

.wrap-img-about {
	max-width: 80%;

	// height: 20%;
	// border-radius: 100%;
	@media (max-width: 900px) {
		display: flex;
		max-width: 100%;
		justify-content: center;
	}
}

.imgabout {
	border-radius: 100%;
	width: 300px;
	height: 300px;
	margin: 25px;

	@media (max-width: 900px) {
		width: 100px;
		height: 100px;
	}
}

.cardbox {
	overflow: hidden;
	padding: 40px;
}

.cardabout {
	// margin-top: 10rem;
	height: 56rem;
	width: 94rem;
	position: relative;

	@media screen and (max-width: 1100px) and (min-height: 600px) {

		margin: auto;
		height: fit-content;
		min-height: 35rem;
		width: 100%;

	}

	@media screen and (min-width: 600px) and (max-height: 400px) {

		margin: auto;
		height: fit-content;
		min-height: 35rem;
		width: 100%;
	}

	@media screen and (max-height: 600px) {

		margin: auto;
		height: fit-content;
		min-height: 35rem;
		width: 100%;
	}

	@media screen and (max-width: 700px) {

		margin: auto;
		height: fit-content;
		min-height: 35rem;
		margin: auto;
		width: fit-content;
	}

	@media (max-width: 600px) {
		margin: auto;
		height: fit-content;
		min-height: 35rem;
		width: auto;

	}

	// @media (min-width: 901px) {
	// 	margin: auto;
	// 	height: fit-content;
	// 	min-height: 35rem;
	// 	width: 100%;

	// }

	@media (max-width: 900px) {
		margin: auto;
		height: fit-content;
		min-height: 35rem;
		// width: auto;

	}
}

.text_container {
	// width: 20%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	// a {
	// 	color: var(--contrast-text);
	// 	text-decoration: underline;
	// }

	// @media screen and (min-width: 600px) and (max-height: 400px) {
	// 	width: 20%;
	// 	display: flex;
	// 	flex-direction: column;
	// 	align-items: center;
	// 	justify-content: center;
	// 	top: 10%;
	// }

	@media screen and (max-height: 400px) {
		width: 50%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		top: 10%;
		margin: auto;
	}

	@media (max-width: 900px) {
		// top: 20px;
		width: 50%;

	}
}

.text1 {
	font-family: 'Phetsarath';
	font-style: normal;
	font-weight: 700;
	font-size: 60px;
	line-height: 77px;
	color: #000000;
	color: var(--contrast-text);
	text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
	font-family: "Kurale", serif;

	@media (max-width: 900px) {
		font-size: 20px;
		height: 50px;
	}
}

.text2 {
	font-family: 'Content';
	font-style: normal;
	font-weight: 400;
	font-size: 23px;
	line-height: 42px;
	color: var(--contrast-text);

	@media (max-width: 900px) {
		font-size: 14px;
		height: 30px;
	}
}

.btn {
	z-index: 500;
	border: none;
	background: transparent;
	color: var(--color-intense);
	cursor: pointer;
	text-decoration: none;

	&:hover {
		font-size: 25px;
		cursor: pointer;
	}
}