  .empty{
    height: 1.8rem;
    opacity: 0;    
    position:  absolute;
    top:3.2rem;
    z-index: 1300;    
    background-color: var(--background-wrap);
    display: none;
   width: 100%;
   /* margin-left: -40px; */
  }
  .success{
    background-color: rgb(72, 255, 121); 
    display: block;
   
  }  
  .attention{
    background-color: rgb(250, 255, 116);
    display: block;
  }
  .error{
    background-color: rgb(255, 3, 53);
    color:white;
    display: block;
  }
  .toast{

    /* margin-top: 1rem; */
    margin-right:10%;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center; 
    animation: popup 5s;
    animation-iteration-count:1;
  }
  @keyframes popup {
    0%{opacity: 0;}
    50%{opacity: 1;}
    100%{opacity: 0;}
  }