.userForm {
  width: 50%;
  margin: auto;

  @media screen and (max-width: 600px) {
    width: 100vw;
  }

  .wrap_opacity {
    background-color: rgb(var(--background-wrap-rgb), var(--wrap-opacity));
    color: var(--color-bg-wrap);
    border-radius: 15px;
    padding: 5px;
    text-align: center;
    width: 90%;
  }

  .wrap_listview {
    color: var(--contrast-text);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    flex-direction: column;
    font-size: 14px;
  }

  .avatarDiv {
    width: 30%;
    max-width: 200px;
    text-align: center;
    max-height: 200px;

    width: 350px;
    height: 367px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-right: 2rem;

    .avatarProfile {
      width: 90%;
      cursor: pointer;
    }
  }

  .backColorBox {
    width: 90%;
    cursor: pointer;
    margin: auto;
    display: flex;

    button {
      width: 3rem;
      font-size: 10px;
      max-height: 3rem;
      line-height: 0;
      text-align: center;
      font-size: 21px;
      border-radius: 5px;
      border-color: transparent;
      background-color: var(--background-header);
      color: var(--color-bg-wrap);
      margin-left: 5px;

      &:hover {

        background-color: var(--background-intense);
        color: white;
      }
    }

    input {
      cursor: pointer;
      border-radius: 2px;
      width: 90%;
      margin: auto;
    }
  }

  .textDiv {
    width: 70%;

    .inputGr {
      margin: auto;
      height: 4rem;
      width: 100%;
      border: none;
      margin-bottom: 2rem;

      input {
        background-color: var(--background-first);
        color: var(--color-bg-wrap);
      }

      @media screen and (max-width: 600px) {
        margin-bottom: 1rem;
      }

      span {
        font-size: 13px;
        font-weight: 600;
        min-width: 8rem;
        color: var(--contrast-text);
        background-color: transparent;
        border: none;
      }
    }

  }

  .BtnSubmit {
    font-size: 13px;
    width: 100%;
    height: 3rem;
    background-color: var(--background-header);
    border-color: var(--background-second);
    color: var(--color-bg-wrap);

    &:hover {
      background-color: var(--background-intense);
      color: white;
    }
  }



}




@media screen and (max-width: 600px) {
  .avatarDiv {
    width: 30%;
    max-width: 200px;
    text-align: center;
  }

}

.imgPreview {
  box-shadow: 3px;
}

.imgGallary {
  width: 30%;
  height: 30%;
}

.btn {
  display: flex;
  justify-content: center;

}

.previewBlock {
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 30%;
  box-shadow: 3px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}